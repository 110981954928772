.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    /* margin-bottom: 1rem; */
}

.portfolio__title{
    text-align: center;
}
.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 0.3rem;
    overflow: hidden;
    /* height: 40%; */
    margin-bottom: 1rem;
}

.portfolio__item h3 {
    margin: 1rem 0 1rem;
}

.portfolio__item-cta {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    /* margin-bottom: 1rem;  */
}
.des{
    padding-left: 1rem;
    height: 7rem;
}

@media screen and (max-width: 1024px){
    .portfolio__container {
        grid-template-columns: 1fr ;
        gap: 1.2rem;
    }
}
  
@media screen and (max-width: 700px){
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
@media screen and (max-width: 1024px){
    .des {
         padding-left: "15px";
         height: 6rem;
    }
}
@media screen and (max-width: 600px){
    .des {
         padding-left: "15px";
         height: 12rem;
    }
}